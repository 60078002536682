<template>
  <div>
    <section class="sign-in-page">
      <b-container fluid>
        <b-row style="height:100vh">
          <b-col
            style="background-color:white;"
            class=" col-md-6 col-lg-6  hidex"
          >
            <div
              class=" text-white"
              style="margin:auto;display:table"
            >
              <img
                :src="bgImageURL"
                style="height:100vh;"
              >

            </div>
          </b-col>

          <b-col
            class="col-md-6 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center"
            style="background-color:white;"
          >
            <div class="w-75 ">
              <div class="showLogo hide">
                <img
                  :src="bgImageURL"
                  style="height:300px; margin: auto; display: table;"
                >
              </div>
              <router-view />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
// import Loader from '@/components/core/loader/Loader'
import logo from '@/assets/images/logo-white.png'
import { core } from '@/config/pluginInit'
import bgImage from '@/assets/images/bioscie.png'
// import Swiper from '@/components/core/slider/Swiper'
// import SwiperSlide from '@/components/core/slider/SwiperSlide'
export default {
  name: 'AuthLayout',
  components: {
    // Loader
    // Swiper,
    // SwiperSlide
  },
  data () {
    return {
      options: {
        slidesPerView: 1,
        loop: true
      },
      logo: logo,
      bgImageURL: bgImage
    }
  },
  mounted () {
    core.index()
  }
}
</script>
<style>
@import url("../assets/css/custom.css");

@media only screen and (max-width: 1057px) {
  .logox {
    display: none;
  }
}

@media only screen and (max-width: 3000px) {
  .hide{
    display: none;
  }
}

@media only screen and (max-width: 701px) {
  .hidex {
    display: none;
  }
  .showLogo{
    display: inline;
  }

}
</style>
